<script setup>
import { ref, watch, onMounted } from 'vue';
import DsFeatureTip from '../ds/DsFeatureTip.vue';
import DsCheckSwitch from 'JIX/components/ds/DsCheckSwitch.vue';
import { getCookie, setCookie } from 'JIX/utils/cookies';

import { __ } from 'JIX/gettext.js';

const props = defineProps({
    showFeatureTip: {
        type: Boolean,
        required: false,
        default: true,
    },
    seenFeatureTip: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const JIX_NEW_JOBSEARCH_COOKIE = 'jix-new-jobsearch';
const JIX_SEEN_NEW_JOBSEARCH_FEATURE_TIP_COOKIE = 'jix-seen-new-jobsearch-feature-tip';
const CATEGORY_NEW_JOBSEARCH = 9; // See JIX::User::Dismiss for categories.
const FEATURE_TIP_TIME_DELAY_IN_MS = 1500;

const jobsearchSwitch = ref();
const featureTip = ref();

const newJobsearch = ref(getCookie(JIX_NEW_JOBSEARCH_COOKIE) === '1');
const seenFeatureTip = ref(getCookie(JIX_SEEN_NEW_JOBSEARCH_FEATURE_TIP_COOKIE) === '1' || props.seenFeatureTip);

watch(newJobsearch, () => {
    setCookie(JIX_NEW_JOBSEARCH_COOKIE, newJobsearch.value ? 1 : 0, { path: '/', samesite: 'Lax' });
    if (window.jixAnalytics) {
        const label = newJobsearch.value ? 'opt-in' : 'opt-out';
        window.jixAnalytics('event', { category: 'jobsearch-version-1', action: 'click', label });
    }
    const url = location.href;
    location.href = url;
});


async function handleDismissFeatureTip() {
    setCookie(JIX_SEEN_NEW_JOBSEARCH_FEATURE_TIP_COOKIE, 1, {
        path: '/',
        samesite: 'Lax',
        maxAge: 60 * 60 * 24 * 365 * 2,
    });
    if (window.Stash.common.userid) {
        const url = '/api/user/dismiss/v1/';
        await fetch(
            url,
            {
                method: 'PUT',
                headers: { 'X-CSRF-Token': window.Stash.common.csrf_token },
                body: JSON.stringify({
                    category: CATEGORY_NEW_JOBSEARCH,
                }),
            },
        );
    }

}

async function handleTryNewJobsearch() {
    await handleDismissFeatureTip();
    newJobsearch.value = true;
}

const featureTipReady = ref(false);
onMounted(() => setTimeout(() => featureTipReady.value = true, FEATURE_TIP_TIME_DELAY_IN_MS));

</script>

<template>
    <div class="position-relative">
        <DsCheckSwitch
            v-model="newJobsearch"
            ref="jobsearchSwitch"
        >
            {{ __('Prøv ny jobsøgning (beta)') }}
        </DsCheckSwitch>
        <DsFeatureTip
            v-if="jobsearchSwitch && showFeatureTip && !seenFeatureTip && featureTipReady"
            ref="featureTip"
            :target="jobsearchSwitch.$el"
            :header="__('Vil du prøve den nye jobsøgning?')"
            header-tag="Beta"
            position="right"
            :auto-show="true"
            :button="{ label: __('Prøv ny jobsøgning (beta)') }"
            @button-click="handleTryNewJobsearch"
            @hiding="handleDismissFeatureTip"
        >
            {{ __('Vær blandt de første til at prøve vores nye søgefunktion - du kan altid skifte tilbage igen.') }}
        </DsFeatureTip>
    </div>
</template>
